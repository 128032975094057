import { Button, Card, Divider, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  fetchArticle,
  fetchArticleText,
  getDownloadPath,
  getScreenshotUrl,
} from "./services";
import AssetsList from "./AssetsList";
import DownloadIcon from "@mui/icons-material/Download";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const ArticleView = ({ article }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState({});
  const [fullArticle, setFullArticle] = useState();

  useEffect(() => {
    const loadData = async () => {
      const metadataRaw = await fetchArticle(article.datapath);
      const articleText = await fetchArticleText(article.datapath);
      setMetadata(metadataRaw);
      setFullArticle(articleText);
    };
    loadData();
  }, [article]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          variant="fullWidth"
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Skjermbilde" />
          <Tab label="Lesemodus" />
          <Tab label="Bilder og vedlegg" />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Box
          component="img"
          sx={{
            width: "100%",
          }}
          alt="Forhåndsvisning av artikkel med design"
          src={getScreenshotUrl(article.datapath)}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Card sx={{ padding: 2, mt: 4 }}>
          <Box xs={12}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              component="a"
              target="_blank"
              sx={{ float: "right" }}
              href={getDownloadPath(article.datapath)}
            >
              Last ned
            </Button>
            <Typography variant="h5" component="h2">
              {metadata.header}
            </Typography>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography sx={{ whiteSpace: "pre-line" }}>{fullArticle}</Typography>
        </Card>
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {article.images && article.images.length && (
          <AssetsList
            datapath={article.datapath}
            images={article.images}
            attachments={article.attachments}
          />
        )}
      </TabPanel>
    </Box>
  );
};

export default ArticleView;
