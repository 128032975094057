import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArchiveAppIndex from "./views/ArchiveAppIndex";
import ArchiveAppView from "./views/ArchiveAppView";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:domain" element={<ArchiveAppView />} />
        <Route path="/" element={<ArchiveAppIndex />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
