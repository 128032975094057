import { useState } from "react";
import ImageCard from "./ImageCard";
import FullScreenImage from "./FullScreenImage";
import AttachmentCard from "./AttachmentCard";
import { Grid } from "@mui/material";

const AssetsList = ({ datapath, images, attachments }) => {
  const [fullscreen, setFullscreen] = useState(null);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={4}
      >
        {images.map((image) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            key={`images_${image.filename}`}
          >
            <ImageCard
              datapath={datapath}
              image={image}
              openFullScreen={(image) => setFullscreen(image)}
            />
          </Grid>
        ))}
        {attachments.map((a) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={`attachments_${a}`}>
            <AttachmentCard datapath={datapath} file={a} />
          </Grid>
        ))}
      </Grid>
      <FullScreenImage
        datapath={datapath}
        image={fullscreen}
        open={fullscreen !== null}
        handleClose={() => setFullscreen(null)}
      />
    </>
  );
};

export default AssetsList;
