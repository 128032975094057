import {
  Card,
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { getAttachmentUrl } from "./services";

const AttachmentCard = ({ datapath, file, openFullScreen }) => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{
            height: {
              xs: "150px",
              md: "200px",
              lg: "250px",
            },
          }}
          image="/attachments.png"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {file}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          target="_blank"
          href={getAttachmentUrl(datapath, file)}
        >
          Last ned
        </Button>
      </CardActions>
    </Card>
  );
};

export default AttachmentCard;
