import {
  Card,
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { getImageUrl } from "./services";

const ImageCard = ({ datapath, image, openFullScreen }) => {
  const { filename, description, alt } = image;
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{
            height: {
              xs: "150px",
              md: "200px",
              lg: "250px",
            },
          }}
          image={getImageUrl(datapath, filename)}
          alt={alt}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {description || filename}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => openFullScreen(image)}
        >
          Vis fullskjerm
        </Button>
      </CardActions>
    </Card>
  );
};

export default ImageCard;
