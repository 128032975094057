import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
  Collapse,
  LinearProgress,
  Drawer,
  IconButton,
  Container,
  useMediaQuery,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { DOMAINS, fetchDomainIndex } from "../components/services";
import { useParams } from "react-router-dom";
import { deepPurple, deepOrange } from "@mui/material/colors";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import ArticleView from "../components/ArticleReader";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const ArticleList = ({
  articles,
  years,
  toggleFolder,
  isFolderOpen,
  handleOpenArticle,
  openArticle,
}) => {
  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItemButton onClick={() => (window.location = "/")}>
          <ListItemAvatar>
            <Avatar color="primary">
              <HomeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Hovedside" />
        </ListItemButton>
        {years.map((year) => {
          return (
            <Fragment key={`year_${year}`}>
              <ListItemButton onClick={() => toggleFolder(year)}>
                <ListItemAvatar>
                  <Avatar color="primary" sx={{ bgcolor: deepPurple[500] }}>
                    <FolderOpenRoundedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={year} />
                {isFolderOpen(year) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isFolderOpen(year)} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  {articles
                    .filter((a) => a.year === year)
                    .map((f) => {
                      return (
                        <ListItemButton
                          selected={openArticle?.datapath === f.datapath}
                          key={`articles_${f.datapath}`}
                          sx={{ pl: 6 }}
                          onClick={() => handleOpenArticle(f)}
                        >
                          <ListItemAvatar>
                            <Avatar
                              color="primary"
                              sx={{ bgcolor: deepOrange[500] }}
                            >
                              <ArticleRoundedIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={f.header}
                            secondary={moment(f.date).format("DD.MM.yyyy")}
                          />
                        </ListItemButton>
                      );
                    })}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>
    </>
  );
};

const ArchiveAppView = () => {
  const routeParams = useParams();
  const domain = routeParams.domain;

  const [menuOpen, setMenuOpen] = useState(false);
  const [openArticle, setOpenArticle] = useState();
  const [openFolders, setOpenFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [articles, setArticles] = useState([]);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    const loadData = async () => {
      const articlesRaw = await fetchDomainIndex(domain);
      const newArticles = articlesRaw.map((f) => {
        f.year = f.date.substr(0, 4);
        return f;
      });
      const uniqueYears = [...new Set(articlesRaw.map((f) => f.year))];
      uniqueYears.sort((a, b) => b - a);
      newArticles.sort((a, b) => `${b.date}`.localeCompare(`${a.date}`));

      setYears(uniqueYears);
      setArticles(newArticles);
      setLoading(false);
    };
    loadData();
  }, [domain]);

  const isFolderOpen = (key) =>
    openFolders ? openFolders.includes(key) : false;

  const toggleFolder = (key) => {
    if (openFolders.filter((e) => e === key).length > 0) {
      const newFolders = [...openFolders].filter((e) => e !== key);
      setOpenFolders(newFolders);
    } else {
      const newFolders = [...openFolders];
      newFolders.push(key);
      setOpenFolders(newFolders);
    }
  };

  if (domain && DOMAINS.filter((e) => e.key === domain).length) {
    return (
      <>
        <AppBar position="relative">
          <Toolbar>
            {!isLarge && (
              <IconButton
                aria-label="Åpne meny"
                size="large"
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
                onClick={() => setMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" color="inherit" noWrap>
              {domain}
            </Typography>
          </Toolbar>
        </AppBar>
        <main>
          {loading && <LinearProgress />}
          {!isLarge && (
            <>
              <Drawer
                anchor="left"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
              >
                <Grid sx={{ minWidth: "250px" }}>
                  <ArticleList
                    handleOpenArticle={(a) => {
                      setOpenArticle(a);
                      setMenuOpen(false);
                    }}
                    openArticle={openArticle}
                    articles={articles}
                    years={years}
                    toggleFolder={toggleFolder}
                    isFolderOpen={isFolderOpen}
                  />
                </Grid>
              </Drawer>
              <Container maxWidth="lg">
                {openArticle && (
                  <Grid item xs={openArticle ? 8 : 12}>
                    <ArticleView article={openArticle} />
                  </Grid>
                )}
              </Container>
            </>
          )}
          {(isLarge || (!isLarge && !openArticle)) && (
            <>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={openArticle ? 4 : 12}>
                  <ArticleList
                    handleOpenArticle={(a) => setOpenArticle(a)}
                    openArticle={openArticle}
                    articles={articles}
                    years={years}
                    toggleFolder={toggleFolder}
                    isFolderOpen={isFolderOpen}
                  />
                </Grid>
                {openArticle && (
                  <Grid item xs={openArticle ? 8 : 12}>
                    <ArticleView article={openArticle} />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </main>
      </>
    );
  }

  return "Siden ble ikke funnet";
};

export default ArchiveAppView;
