import {
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  IconButton,
} from "@mui/material";
import { getImageUrl } from "./services";
import CloseIcon from "@mui/icons-material/Close";

const FullScreenImage = ({ datapath, open, handleClose, image }) => {
  const { filename, description, alt } = image || {};
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {alt}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="div"
        sx={{
          width: "100vw",
          height: "80vh",
          background: `url('${getImageUrl(datapath, filename)}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "contain",
        }}
        alt="Forhåndsvisning av artikkel med design"
      />
      <Typography sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
        {description}
      </Typography>
    </Dialog>
  );
};

export default FullScreenImage;
