export const DOMAINS = [
  {
    key: "metodistkirken.no",
    title: "Metodistkirken i Norge",
    description:
      "Arkiverte artikler fra Metodistkirken i Norge i perioden  2012 - 2022",
    logo: "/metodistkirken.png",
  },
  {
    key: "misjonsselskap.no",
    title: "Metodistkirkens misjonsselskap",
    description:
      "Arkiverte artikler fra Metodistkirkens misjonsselskap i perioden  2007 - 2022",
    logo: "/misjonsselskapet.png",
  },
  {
    key: "mbu.no",
    title: "Metodistkirkens Barne- og Ungdomsforbund",
    description:
      "Arkiverte artikler fra Metodistkirkens Barne- og Ungdomsforbund i perioden  2015 - 2022",
    logo: "/mbu.png",
  },
  {
    key: "ms-speider.no",
    title: "Metodistkirkens speiderkorps",
    description:
      "Arkiverte artikler fra Metodistkirkens speiderkorps i perioden  2005 - 2022",
    logo: "/msspeider.png",
  },
  {
    key: "larvik.metodistkirken.no",
    title: "Metodistkirken i Larvik",
    description:
      "Arkiverte artikler fra Metodistkirken i Larvik i perioden  2013 - 2023",
    logo: "/metodistkirkenilarvik.png",
  },
];

export const fetchDomainIndex = async (domain) => {
  const r = await fetch(`/data/${domain}/index.json`);
  if (r.ok) return await r.json();
  return [];
};

export const fetchArticle = async (datapath) => {
  const r = await fetch(`/data/${datapath}`);
  if (r.ok) return await r.json();
  return {};
};

export const fetchArticleText = async (datapath) => {
  const r = await fetch(
    `/data/${datapath}`.replace("metadata.json", "full-article.txt")
  );
  if (r.ok) return await r.text();
  return {};
};

export const getScreenshotUrl = (datapath) =>
  `/data/${datapath}`.replace("metadata.json", "full-screenshot.png");

export const getDownloadPath = (datapath) =>
  `/data/${datapath}`.replace("metadata.json", "full-screenshot.pdf");

export const getImageUrl = (datapath, image) =>
  [`/data/${datapath}`.replace("metadata.json", "images"), "/", image].join("");

export const getAttachmentUrl = (datapath, file) =>
  [`/data/${datapath}`.replace("metadata.json", "attachments"), "/", file].join(
    ""
  );
